.button {
  min-width: 64px;
  min-height: 36px;
  padding: 8px 16px;

  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  color: #fff;
  text-transform: uppercase;
  line-height: 1.4;

  background-color: #245cc2;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,0.075);
    color: #245cc2;
  }
}