.mic {
  padding: 14px 30px;
}

.mic-icon {
  color: #305cc5 !important;
  font-size: 30px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  top: -48px;
  transform: translate3d(-50%, 0px, 0px);
  background-color: #ffffff;
  border-radius: 100px;
  border-color: #305bc5;
  border-width: 6px;
  border-style: solid;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 30px 0px;
  cursor: pointer;
  transition: 300ms ease all;
  font-size: 50px !important;

  &:hover {
    transform: translate3d(-50%, 0px, 0px) scale3d(1.06,1.06,1.06);
    background-color: #fff;
  }
}
.hidden {
  display: none;
}

.stop-icon {
  color: #305bc5;
  font-size: 30px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  top: -48px;
  transform: translate3d(-50%, 0px, 0px);
  background-color: #ffffff;
  border-radius: 100px;
  border-color: #305bc5;
  border-width: 6px;
  border-style: solid;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 30px 0px;
  cursor: pointer;
  transition: 300ms ease all;
  font-size: 50px !important;

  &:hover {
    transform: translate3d(-50%, 0px, 0px) scale3d(1.06,1.06,1.06);
    background-color: #fff;
  }
}

@media screen and (max-width: 480px) {
}
