.login-page {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #245cc2!important;

  .login {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: center;
	    width: 460px;
	    align-items: center;
	    form {
	    	width:100%;
	    	.login-secondaryActions {
			    display: block;
			    margin-top: 15px;
			    a {
			    	color: #fff;
			    	margin: 0;
			    	text-transform: capitalize;
			    	padding: 0;
			    	min-width: auto;
			    }
			    a:last-child {
			    	float: right;
			    }
			    a:hover {
			    	background-color: transparent;
			    	opacity: 0.9;
			    }
			}
	    }
	    .MuiFormControl-root {
		    margin-left: 0 !important;
		    margin-right: 0 !important;
		}
		.login-secondaryActions b {
		    margin-left: 3px;
		}
		.pointer_none {
			pointer-events: none;
		}
		.verify_link {
		    a {
		    	font-weight: 500;
		    	color: white;
		    	margin-right: 3px !important;
		    }
		}
	}
	.login.forget-password, .login.verify, .login.reset-password {
		.login-secondaryActions {
			text-align: center;
			a {
		    	float: none !important;
		    }
		}
	}
	h1, h2 {
	text-transform:capitalize;
	font-weight: 500;
	}
	h1, p, h2 {
	 color: #fff;
	 text-align: center;
	}
	input {
		border-radius: 3px;
	    padding: 12px 8px;
	    border: 1px solid #fff;
	    color: white;
	}
	input::-webkit-input-placeholder {
      color: #fff;
    }
	.MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before {
	    display: none;
	}
	.resend {
		width: 100%;
	}
	button, .resend a {
		background: white;
	    color: #245cc2;
	    font-weight: bold;
	    flex-grow: 1;
	    max-width: 100%;
	    padding: 12px 0;
	    border-radius: 0;
	    font-size: 16px;
	    text-transform: capitalize !important;
	    width:100%;
        margin: 0px;
		margin-top: 20px;
		margin-bottom: 0;
	}
	button:hover {
		color: #fff;
	}
	.login.verify p {
		text-align: center;
	    margin: 0;
	    margin-top: 8px;
	}
	.login.verify h1 {
	    margin-bottom: 0px;
	}
	.footer {
	    background-color: transparent;
	    box-shadow: none;
	    border-top: 1px solid rgba(255, 255, 255, 0.34);
	    .container a {
	    	color: #fff;
	    }
	}
	.MuiSnackbarContent-action button {
	    background: transparent;
	    padding: 0;
	    color: white;
	    margin: 0;
	}
	.MuiInput-underline.Mui-error:after {
	    border-bottom-color: transparent;
	}
	.MuiInput-underline.Mui-error input {
	    border: 1px solid #f44336;
	}
}
@media screen and (max-width: 480px) {
	.login-page .login form {
	    width: 90%;
	}
	.login-page .login form h2 {
		font-size: 26px;
	}
	.login-page .login {
	    width: 100%;
	}
	.login img {
	    width: 80%;
	}
	.login-secondaryActions a {
	    margin-bottom: 10px;
	    margin-top: 0;
	    width: 100%;
	}
}
