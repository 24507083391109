.chat-footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 40px;
  padding: 25px 0 15px 0;
  background: #305cc5;

  & > .container {
    align-items: center;
  }

  &-input {
    position: relative;
    width: 450px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    
    &__input {
      width: 100%;
      padding: 15px 20px;
      margin: 0;

      background: rgb(227, 232, 235);
      border: none;
      border-radius: 30px;
      box-shadow: none;
      box-sizing: border-box;
      outline: none;
      
      font-size: 16px;
    }
    &__button {
      position: absolute;
      right: 7px;
      top: 6px;
      border-radius: 24px 100px 100px 24px !important;
      background-color: #305bc5 !important;
      border-color: #305bc5 !important;
      color: #ffffff;
      font-weight: bold;

      &:hover {
        background-color: rgba(48, 92, 197, 0.9) !important;
        border-color: rgba(48, 92, 197, 0.9)  !important;
        color: #fff !important;

      }
    }
  } // end &-input
} // end .chat-footer
@media screen and (max-width: 480px) {
  .chat-footer-input {
    width: 100%;
    max-width: 100%;
  }
}
