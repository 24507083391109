.footer {
  position: fixed;
  // z-index: 1038;
  left: 0;
  right: 0;
  bottom: 0;
  & > .container {
    display: flex;
    flex-direction: column;
    a {
      background: none;
      color: #305cc2;
      box-shadow: none;
      padding: 0;
      margin-right: 12px;
      text-transform: capitalize;
  }
  }

  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  text-align: center;

  &__actions {
    align-self: flex-end;
    & > * {
      margin-right: 7px;
      &:last-child { margin-right: 0}
    }
  }
}

