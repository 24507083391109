html, body { height: 100%; }

#root {
  display: flex;
  flex-direction: column;
  flex: 1;

  height: 100%;
  width: 100%;
}

body {
  /*background-color: rgba(241, 242, 247, 1);*/
  background-color: #fff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
}
.base_color {
  background: #305cc5 !important;
}
p {
  white-space: normal;
  word-break: break-word;
}
.border-custom {
  border-bottom: 1px solid #e2e2e2;
}
.header .container {
    padding: 3px 15px;
}
.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}
.app-main {
  flex: 1;
  overflow: hidden;
  padding-bottom: 50px;
  .container {
    h1 {
      padding-bottom: 8px;
    }
  }
}
.profile-options {
    border-color: #eee;
    .dropdown-toggle::after {
      display: none;
    }
    button:focus {
      outline: none;
      box-shadow: none !important;
    }
    img {
      margin-right: -10px;
    }
    .dropdown-menu.show {
      left: initial !important;
      right: 0px;
      transform: none !important;
      top: 38px !important;
      border-radius: 0;
    }
}
.app-main-inner {
  width: 100%;
  height: 86%;
  box-sizing: border-box;
  overflow-y: auto;
  .container {
    padding-top: 40px !important;
  }
}

/*Chat Footer*/
.chat-footer {
  .MuiIcon-root {
    width: auto;
    height: auto;
  }
}

/*Profile*/
.user-info {
  color: #000;
  .username {
    text-align: center;
    background: #305cc2;
    color: white;
    padding: 15px 0px;
    margin-bottom: 30px;
    h3 {
      margin-bottom: 8px;
      margin-top: 0;
      color: white;
      font-size: 1.17em;
    }
    span {
      color: rgba(255, 255, 255, 0.83);
    }
  }
  h3 {
    text-transform: uppercase;
    color: #787878;
    margin-top: 30px;
    margin-bottom: 8px;
    font-size: 1.17em;
  }
  .info-value {
    color: #787878
  }
  .font-weight {
    font-weight: 500;
  }
  .edit {
      justify-content: flex-end;
      display: flex;
      padding-right: 0;
      a:first-child {
        margin-right:8px;
      }
  }
}
.profile-options {
  a {
    margin-right: 5px;
  }
  a, button {
    background: #305cc5;
    color: white;
    text-transform: capitalize !important;
  }
  a:hover, button:hover {
    background: rgba(48, 92, 197, 0.9);
    color: #fff;
  }
}
.btn-primary {
    background-color: #305cc5 !important;
    border-color: #305cc5 !important;
}
.btn-primary:hover {
  background: rgba(48, 92, 197, 0.9) !important;
  color: #fff !important;
}
.pwd .modal-footer {
  padding: 0 !important;
}
.spacing-border {
  border-bottom: 1px solid #a1a1a1;
  padding: 15px 0;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.fade {
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}
.modal-dialog {
  margin: 9.75rem auto!important;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  margin:0 auto;
  pointer-events: auto;
  background-color: #fff;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  background-color: #f7f7f8;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 500;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-header .close {
    display: none;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}
.modal-footer {
  .row {
    padding: 0;
  }
  .col {
    text-align: center;
    height: 100%;
    padding: 10.5px 0;
    border-right: 1px solid #e2e2e2;
    button {
      color: #2f55c0;
      font-weight: bold;
    }
    button:hover {
      background-color: transparent;
      opacity: 0.9;
    }
  }
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 30px 1rem;
 background-color: #2f55c0;
 .newPasscode {
    .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #fff;
    }
    input {
      color: #fff;
    }
    .MuiFormControl-root {
      width:100%;
    }
    .MuiFormControl-root:nth-child(1) {
      margin-bottom: 15px
    }
    input::-webkit-input-placeholder {
      color: #fff;
    }
 }
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  background: #f7f7f8;
}
.modal-footer>:not(:last-child) {
  margin-right: .25rem;
}
.modal-footer>:not(:first-child) {
  margin-left: .25rem;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: .5;
}
/*Edit Profile*/
.editprofile {
  input, .MuiFormControl-root {
    width: 100%;
  }
  h3 {
    color: #777777;
    font-size: 1.17em;
    padding: 15px 0;
    margin: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
  button.update {
    background-color: #f50057 !important;
    margin-top: 20px !important;
    color: #fff !important;
    padding: 8px 40px !important;
    text-transform: capitalize !important;
  }
  button:hover {
    opacity: 0.9;
  }
  .btn {
    text-align: center;
  }
}

/*Timeline*/
.showTimeline {
  .card {
    margin-bottom: 15px;
    border-radius: 5px !important;
    .card-body {
      border-bottom: 1px solid rgba(0,0,0,.125);
    }
  }
  .card-header {
    background: #d8d8d8;
    .heading {
      font-weight: 500;
    }
  }
  span {
      float: right;
  }
  .infoBlock {
    margin-bottom: 8px;
    .infovalue {
      color: #000;
      font-size: 18px;
    }
    .infoTitle {
      color: #787878;
      font-size: 15px;
    }
  }
}
/*custom checkboxes*/
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #305cc5 !important;
    background-color: #305cc5 !important;
}

/*Command*/
.voice-command {
  h4 {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 991px) {
  .navbar-nav {
    .btn-primary {
      background: transparent !important;
      border-color: #e2e2e2 !important;
    }
  }
  .chat-footer {
    bottom: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  .header {
      box-shadow: none !important; 
  } 
  .header img {
    width: 90px !important;
  } 
  .navbar-nav {
    margin-top: 14px;
    .btn-primary {
      background: transparent !important;
      border-color: #e2e2e2 !important;
    }
  } 
  .navbar-expand-lg {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    a {
      margin-right: initial !important;
      .sm-logo {
        position: absolute;
        left: 50%;
        top: 12px;
        transform: translate(-50%);
      }
    }
  }
  .header img.vol {
    position: absolute;
    width: 22px !important;
    top: 10px;
  }
  .sm-profile {
    button, button:hover {
        background: transparent !important;
    }
    .dropdown-menu {
      padding: 0;
      a, button {
        background: transparent !important;
        color: #000 !important;
        border-bottom: 1px solid #e2e2e2;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  /*timeline*/
  .filter {
    b {
      width: 100%;
      float: left;
      margin-bottom: 10px;
    }
  }
  .modal-dialog {
    width: 95% !important;
  }
}
@media screen and (max-width: 480px) {
  .h2 {
    font-size: 1.5rem !important;
  }
  .profile-options img {
      width: 20px;
      margin-top: 5px;
  }
  .showTimeline .card-header .heading {
    font-size: 14px;
  }
  .footer  {
    .container {
      a {
        font-size: 13px;
      }
    }
  }
  /*timeline*/
  .filter {
    b {
      width: 100%;
      float: left;
      margin-bottom: 10px;
    }
    .custom-control {
      width: 50%;
      float: left;
      margin-right: 0;
    }
  }
}