
ul.messageList {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  & > li.message {
    margin-bottom: 10px;
    padding: 12px 23px;
    /*max-width: 65%;*/
    max-width: 45%;

    border-radius: 6px;
    
    box-shadow: 0 0 15px 0 rgba(26, 26, 26, 0.10);
    box-sizing: border-box;
    list-style: none;

    font-size: 18px;
    line-height: 1.4;
    
    &.is-BOT {
      align-self: flex-start;
      border-radius: 25px 25px 25px 0px;
      background-color: #efefef;
      color: #000;
      box-shadow: none;
      font-weight: 500;
    } // end &.is-BOT
    
    &.is-PERSON {
      align-self: flex-end;
      border-radius: 25px 25px 0px 25px;
      background: #305cc5;
      color: #fff;
      font-weight: 500;
      box-shadow: none;
    } // end &.is-PERSON
  } // end & > li.message
} // end ul.messagelist

.messageList-spacer {
  height: 70px;
}

@media screen and (max-width: 575px) {
  .app-main-inner {
    height: 78%;
  }
  ul.messageList {
    & > li.message {
      max-width: 65%;
    }
  }
}